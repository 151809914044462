import { Vue, Component } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';
import { BlockchainStateSlice } from '@/store/models';

@Component
export default class DropdownMenu extends Vue {
  @StateClass blockchain!: BlockchainStateSlice;
  @Action logOut!: Function
  @Action(addToastMessage) addToastMessage!: Function;

  get brand(): string {
    return require('../../../../../whitelabel.config').name;
  }

  async onMetamaskClick(): Promise<void> {
    if (!this.blockchain.isMetamaskAccountConnected) {
      const providerDetected = await detectEthereumProvider({ mustBeMetaMask: true });
      // @ts-ignore
      if (providerDetected) {
        // @ts-ignore
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []);
      } else {
        this.addToastMessage({
          text: 'Make sure you have metamask extension installed on your browser.',
          type: 'danger',
        });
      }
    }
  }

  onLogOutClick(): void {
    this.logOut({ redirect: '/' });
  }
}
